@color-blue: #40ADEA;
@color-red: #FF0033;
@tablet: ~'(max-width: 1180px)';
@mobile: ~'(max-width: 600px)';

.hover(@params){
	@media (min-width: 1024px){
		&:hover{
			@params();
		}
	}
}

.placeholder(@params){
	&::-webkit-input-placeholder {
		@params();
	}
	&::-moz-placeholder {
		@params();
	}
	&:-moz-placeholder {
		@params();
	}
	&:-ms-input-placeholder {
		@params();
	}
}

@font-face{
	font-family: 'Rubik';
	src: url('./fonts/Rubik-Regular.woff2') format('woff2'), url('./fonts/Rubik-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}

@font-face{
	font-family: 'Rubik';
	src: url('./fonts/Rubik-Bold.woff2') format('woff2'), url('./fonts/Rubik-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
}

html, body {
	font-family: 'Rubik';
	font-weight: 400;
	font-size: 16px;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

a{
	color: @color-blue;

	.hover({
		opacity: 0.5;
	});
}

div, h1, p, input{
	box-sizing: border-box;
	margin: 0;
}

.landing{
	text-align: center;
	padding: 65px 0 59px 0;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	background-color: #F7F8FA;
	
	@media @tablet{
		padding: 40px;
	}

	@media @mobile{
		padding: 0;
	}

	&__holder{
		max-width: 960px;
		width: 100%;
		display: inline-block;
		text-align: left;
		padding: 27px 55px 74px 55px;
		/* display: flex; */
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #FFFFFF;

		@media @tablet{
			padding: 27px 45px 40px 45px;
		}

		@media @mobile{
			border: none;
			padding: 5px 18px;
		}
	}

	&__logotype{
		width: 140px;
		height: 140px;
		margin-bottom: 20px;
		margin: 0 auto;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url('./images/logotype-default.png');
	}

	&__img{
		display: block;
		margin: 0 auto;
		max-width: 100%;
		max-height: 400px;
	}

	&__video{
		max-width: 100%;
		margin: 0 auto;
		max-width: 711px;
	}

	&__video-holder{
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
	}

	&__video-frame{
		position: absolute;
		left: 0;
		top: 0;
		width: 100% !important;
		height: 100% !important;
	}

	&__head{
		width: 100%;
	}

	&__title{
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		margin-top: 20px;
		margin-bottom: 21px;
		text-align: left;

		@media @mobile{
			font-size: 18px;
			line-height: 21px;
			margin-bottom: 5px;
		}
	}

	&__content{
		line-height: 19px;
		width: 100%;

		@media @mobile{
			font-size: 16px;
			line-height: 16px;
		}

		p{
			margin-bottom: 20px;

			@media @mobile{
				margin-bottom: 0;
			}

			&:last-child{
				margin-bottom: 0;
			}
		}

		span{
			border-bottom: 1px solid black;
		}
	}

	&__form{
		text-align: center;
		margin-top: 23px;
		display: flex;
		justify-content: center;
		width: 100%;

		@media @mobile{
			margin-top: 10px;
			display: block;
		}
	}

	&__form-field{
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;
		position: relative;
		max-width: 50%;
		width: 100%;

		@media @mobile{
			margin-right: 0px;
			max-width: 100%;
			padding: 6px 0px;
		}

		&:last-child{
			margin-right: 0;

			.landing__form-error{
				@media @mobile{
					right: 0;
					left: auto;
				}
			}
		}
	}

	&__form-input{
		border: none;
		padding: 2px 0px;
		border-radius: 0;
		font-size: 15px;
		line-height: 18px;
		width: 100%;
		background-color: #F7F8FA;

		.placeholder({
			color: #D9DBE6;
			font-size: 15px;
		});
	}

	&__form-error{
		position: absolute;
		left: 0;
		top: 100%;
		font-size: 14px;
		line-height: 17px;
		color: @color-red;
		white-space: nowrap;
		@media @mobile{
			position: relative ;
		}
	}

	&__messengers{
		display: flex;
		justify-content: center;
		margin-top: 19px;
		width: 100%;

		@media @mobile{
			margin: 15px auto;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}

		&_2{
			.landing__messengers-item{
				@media @mobile{
					&:nth-child(1){
						width: calc((100% - 12px) / 2);
						margin-right: 12px;
					}

					&:nth-child(2){
						width: calc((100% - 12px) / 2);
					}
				}
			}
		}

		&_3{
			.landing__messengers-item{
				@media @mobile{
					&:nth-child(1){
						width: calc((100% - 12px) / 2);
						margin-right: 12px;
					}

					&:nth-child(2){
						width: calc((100% - 12px) / 2);
					}

					&:nth-child(3){
						width: 100%;
						margin-top: 4px;
					}
				}
			}
		}

		&_4{
			.landing__messengers-item{
				@media @mobile{
					&:nth-child(1), &:nth-child(3){
						width: calc((100% - 12px) / 2);
						margin-right: 12px;
					}

					&:nth-child(2), &:nth-child(4){
						width: calc((100% - 12px) / 2);
					}

					&:nth-child(3), &:nth-child(4){
						margin-top: 3px;
					}
				}
			}
		}
	}

	div.mobile_2{
		direction: ltr;
		display: flex;
		justify-content: center;
		width: 50%;
	}

	.mobile &__messengers-item{
		border-radius: 8px;
		width: 77px;
		height: 76px;
		margin-right: auto;
	}

	&__messengers-item{
		max-width: 400px;
		margin-right: 21px;
		text-align: center;
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		color: white;
		font-size: 24px;
		line-height: 28px;
		text-transform: capitalize;

		@media @tablet{
			font-size: 16px;
			line-height: 22px;
			margin-right: 15px;
			height: 45px;
		}

		@media @mobile{
			height: 40px;
			font-size: 14px;
			line-height: 17px;
			max-width: none;
			margin-right: 0;
		}

		&:last-child{
			margin-right: 0;
		}

		&_vk{
			background-color: #0077FF;

			&::before{
				background-image: url('./images/ic-vk.svg');
			}
		}

		&_telegram{
			background-color: #2CA5E0;

			&::before{
				background-image: url('./images/ic-tl.svg');
			}
		}

		&_messenger{
			background-color: #1877F2;

			&::before{
				background-image: url('./images/ic-fb.svg');
			}
		}

		&_viber{
			background-color: #675DA9;

			&::before{
				background-image: url('./images/ic-vb.svg');
			}
		}

		&::before{
			content: '';
			display: block;
			width: 30px;
			height: 30px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin: auto 7px;

			@media @tablet{
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}

			@media @mobile{
				width: 32px;
				height: 32px;
				margin-right: 4px;
			}
		}
	}

	&__policy{
		font-size: 14px;
		line-height: 17px;
		text-align: left;
		margin-top: 21px;
		

		@media @mobile{
			font-size: 14px;
			line-height: 14px;
			margin-top: 4px;
		}
	}

	&__production{
		font-size: 14px;
		line-height: 17px;
		text-align: left;
		margin-top: 19px;

		@media @mobile{
			margin-top: 6px;
			font-size: 14px;
			line-height: 14px;
		}
	}
}

.PhoneInputInput {
	border: none;
    background: #F7F8FA;
	font-family: 'Rubik';
    font-weight: 400;

	.placeholder({
		color: #D9DBE6;
		word-break: break-all;
	});
}

.input_email, .input_phone {
	color: #B1BFD4;
	background: #F7F8FA;
	width: 100%;
	text-align: left;
	padding: 4px 8px;
	border: 1px solid #D9DBE6;
	font-size: 12px;
	height: 44px;
	border-radius: 4px;

	@media @mobile{
		height: auto;
	}
}

.input_email input:focus, input:active, input:focus-visible  {
	outline: none;
	background: #F7F8FA;
}

.input_email input[placeholder], [placeholder], *[placeholder] {
    background: #F7F8FA !important;
}

.PhoneInputCountry {
	border-right: 1px solid #D9DBE6;
    padding-right: 8px;
}